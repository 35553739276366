<template>
  <div v-if="configuration.countryLocale.locale === 'uae'">
    <UaePoilicy />
  </div>
  <div v-else>
    <GlobalPolicy />
  </div>
</template>

<script>
import GlobalPolicy from "./global.vue";
import UaePoilicy from "./uae.vue";
import configuration from "../../config.json";

export default {
  setup() {
    return {
      configuration
    };
  },
  components: {
    GlobalPolicy,
    UaePoilicy
  }
};
</script>
