<template>
  <v-div v-if="loading">
    <Loader></Loader>
  </v-div>

  <header-component></header-component>
  <Notification></Notification>
  <router-view></router-view>
  <footer-component></footer-component>
</template>

<script>
import FooterComponent from "../components/footer/FooterComponent.vue";
import HeaderComponent from "../components/header/HeaderComponent.vue";
import Loader from "../components/Loader/index.vue";
import { storeToRefs } from "pinia";
import { loaderStore, useCommonStore } from "../stores";
import Notification from "../components/alert/Notification.vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import configuration from "../config.json";

import { onMounted } from "vue";
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Loader,
    Notification,
  },
  mounted() {
    const { global_tracker_id } = configuration.gtag;
    if (global_tracker_id) {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + global_tracker_id;
      script.async = true;
      document.head.appendChild(script);
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', global_tracker_id);
    }

  },
  setup() {
    const { loading } = storeToRefs(loaderStore());
    const commonStore = useCommonStore();

    return {
      loading,
    };
  },
};
</script>
<style>
@font-face {
  font-family: "Lato";
  src: local("Lato"), url(../fonts/Lato-Regular.ttf) format("truetype");
}
</style>
