import { axiosInstance, scbAxiosInstance } from "../../services"
import orgImage from "@/../assets/images/blubirch_logo.png"
import Skeleton from "../../components/Skeleton/index.vue";
import configuration from "../../config.json";

export default {
  data() {
    return {
      services: [],
      loading: true,
    }
  },
  components: {
    Skeleton
  },
  mounted() {
    this.getMarketPlace()
    this.loading = true;
  },

  methods: {
    replaceByDefault(e) {
      e.target.src = orgImage
    },
    handleRedirect(link, bidType) {
      this.$router.push(link + `?BiddingMode=${bidType}`)
    },

    async getMarketPlace() {
      let url1 = `/api/organizations/fetch_marketplace`;
      let url2 = "/api/external_service/get_marketplaces";
      let request1 = axiosInstance.get(url1).catch(err => err?.response);
      let request2 = configuration.Lots.Open.enabled ? scbAxiosInstance.get(url2).catch(err => err?.response) : () => {};
      let [marketplace, scbMarketplace] = await Promise.all([request1, request2]);
      this.loading = false;
      let scbOpenMarketPlace = configuration.Lots.Open.enabled ? scbMarketplace?.data?.external_service?.map((item) => {
        return {
          ...item,
          card_url: `/${item.card_url.split("/")[3]}/products`,
          type: "Open"
        };
      }) : [];
      const remarkingLots = marketplace?.data?.marketplaces || [];
      var combinedResult = [...remarkingLots, ...scbOpenMarketPlace];
      combinedResult = combinedResult.filter(rec => rec?.id);
      const activeServices = combinedResult.reduce((acc, item) => {
        const existingItem = acc.find(element => (element.name === item.name) || (element.name.split(" ")[0] === item.name.split(" ")[0]) || (element.name.split(".Ltd.Pvd")[0] === item.name.split(" ")[0]));
        const isOpen = (item.type === 'Open');
        if (existingItem) {
          let bidType = (isOpen && (existingItem.active_lots < 1) && (item.active_lots > 0)) ? 'Open' : 'Hybrid';
          existingItem.bidType = bidType;
          existingItem.image = item?.image_url;
          existingItem.lot_count += item.active_lots;
        } else {
          acc.push({ 
            ...item, 
            name: item.name,
            lot_count: item.active_lots, 
            link: item.card_url,
            bidType: isOpen ? 'Open' : 'Hybrid',
            image: item.image_url
          });
        }
        return acc;
      }, []);
      const blubirchLots = activeServices.filter(a => a.id === 'bb');
      let otherLots = activeServices.filter(a => a.id !== 'bb');
      otherLots = otherLots.sort((a,b) => b.active_lots - a.active_lots);
      this.services = [...blubirchLots, ...otherLots]
    }
  },

}